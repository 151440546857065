<template>
  <div>
    <v-row class="justify-center">
      <v-card width="500" class="mt-15">
        <div class="mt-5">
          <v-img
            class="mx-auto"
            src="img/logo.png"
            height="100"
            width="100"
          ></v-img>
        </div>
        <h2 class="text-center mt-2">Login</h2>
        <v-form ref="dform" lazy-validation @submit.prevent>
          <v-card-text>
            <v-text-field
              v-model="dform.Username"
              color="success"
              label="Username"
              prepend-icon="mdi-account-circle"
              required
            />
            <v-text-field
              v-model="dform.Passwd"
              color="success"
              label="Password"
              :type="showPasswd ? 'text' : 'password'"
              prepend-icon="mdi-lock"
              :append-icon="showPasswd ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="showPasswd = !showPasswd"
              required
            />
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-btn
            :loading="loading"
            class="mx-auto"
            color="success"
            @click="login"
            >Login</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-snackbar
        v-model.trim="snackbar"
        :color="snackColor"
        bottom
        :timeout="5000"
      >
        {{ snackMsg }}
        <template v-slot:action="{ attrs }">
          <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { apiClient } from "@/services";
import { snackMixin } from "@/mixins";

export default {
  name: "Login",
  mixins: [snackMixin],

  data() {
    return {
      dform: this.initializeForm(),
      showPasswd: false,
      routes: [],
      selectedRoutes: [],
      routeForms: [],
      routeTables: [],
    };
  },
  created() {},
  methods: {
    initializeForm() {
      return {
        Username: "",
        Passwd: "",
      };
    },
    ...mapActions(["setLogin", "setCurUser", "setForms", "setTables"]),

    async login() {
      if (!this.$refs.dform.validate()) {
        return;
      }
      this.loading = true;
      try {
        const res = await apiClient.post("/login", this.dform);
        if (res.data.id) {
          this.routes = res.data.RouteSelected;
          if (this.routes.length) {
            this.routes.forEach((id) => {
              apiClient
                .get("/route?u=1&id=" + id)
                .then((res) => {
                  if (res.data.id) {
                    this.selectedRoutes.push(res.data);
                    this.routeForms = this.selectedRoutes.filter(
                      (route) => route.type == "forms"
                    );
                    this.routeTables = this.selectedRoutes.filter(
                      (route) => route.type == "tables"
                    );
                    this.setForms(this.routeForms);
                    this.setTables(this.routeTables);
                  } else {
                    this.displayMsg(res.data, "error");
                  }
                })
                .catch((err) => {
                  this.displayMsg(err.message, "error");
                });
            });
          } else {
            this.setForms(res.data.RouteSelected);
            this.setTables(res.data.RouteSelected);
          }
          this.setCurUser(res.data);
          this.setLogin(true);
          setTimeout(() => {
            this.$router.push({ name: "Dashboard" });
          }, 1000);
          this.displayMsg("You have login Succesfully");
          this.loading = false;
        } else {
          this.displayMsg(res.data, "error");
        }
      } catch (err) {
        this.displayMsg(err, "error");
      }
    },
  },
};
</script>
